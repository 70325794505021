// Carrousel3
function carrousel3(opt) {
  var
    $container = $(opt.container).eq(0),
    ret = $.noop,
    curMedia = null;

  if($container.length) {
    var
      $carousel = $container.find('.listItems'),
      $items = $carousel.find('.item'),

      settings = {
        mediaDesktop: {},
        mediaTablet: {},
        mediaSmartphone: {}
      };

    for(var key in settings) {
      if(settings.hasOwnProperty(key)) {
        if(opt[key] === 'destroy') {
          settings[key] = 'destroy';
        }
        else {
          settings[key] = $.extend({
            next: opt.container + ' .nextCaroussel3 button',
            prev: opt.container + ' .prevCaroussel3 button'
          }, carrousel3.defaults, opt.common, opt[key]);
        }
      }
    }

    var destroy = function (media) {
      $carousel
        .cycle('destroy')
        .off('cycle-destroyed swipe touchstart');

      $container.find('.prevNextCaroussel3, .cycle-sentinel').remove();

      setTimeout(function () {
        if(media) {
          (settings[media].onDestroy || $.noop)($container, $carousel, $items);
        }
      }, 200);
    };

    ret = function (media) {
      var cfg = settings[media];

      if($carousel.data('cycle.API')) {
        destroy(curMedia);
      }

      curMedia = media;

      if(cfg !== 'destroy' && $items.length > cfg.carouselVisible) {
        if(!$container.find('.prevNextCaroussel3').length) {
          $container.prepend('\
            <ul class="prevNextCaroussel3">\
              <li class="prevCaroussel3">\
                <button type="button" class="resetButton">\
                  <span class="ghost">Précédent</span>\
                </button>\
              </li>\
              <li class="nextCaroussel3">\
                <button type="button" class="resetButton">\
                  <span class="ghost">Suivant</span>\
                </button>\
              </li>\
            </ul>');
        }

        (settings[curMedia].onInit || $.noop)($container, $carousel, $items);

        $carousel.on('cycle-update-view', function(event, optionHash, slideOptionsHash, currentSlideEl) {
            $carousel.find('.item a').attr('tabindex', '-1');
            $(currentSlideEl).find('a').attr('tabindex', '0');
        });

        $carousel.cycle(cfg);
      }
    };
  }

  return ret;
}

carrousel3.defaults = {
  allowWrap: true,
  carouselFluid: true,
  carouselVisible: 6,
  fx: 'carousel',
  log: false,
  slides: '.item',
  swipe: true,
  timeout: 0
};
