/*
 * Main menu 1
 * Author : Steven Mouret @ Stratis
 * Version 0.1
 *
 *  Options :
 *  on menu container .menu-main-1 add attribute data-menu-main-1-calc="true" to calculate height of each level.
 *  on li, class 'menu-main-1__item--no-button' item without action button, this is the link that opens the menu
 *  on li, class 'menu-main-1__item--no-action-in-desktop' item without action, level is displayed, no opening.
 *
 *   HTML Structure
 *
 *   <ul class="menu-main-1__listitems-1">
 *       <li class="menu-main-1__item menu-main-1__item--no-button">
 *           <a href="http://www.google.fr">item 1 C</a>
 *           <div class="menu-main-1__sublevel">
 *             ...
 *           </div>
 *      </li>
 *  </ul>
 * */

'use strict';

var menuMain1 = function (media) {
    var subLevel;
    var eventFunction;
    var closingButtonFunction;
    var mainMenu1 = document.querySelector('.menu-main-1');
    var dataCalc = mainMenu1.dataset.menuMainOneCalc;

    var itemStyle = "menu-main-1__item";
    var itemNoButtonStyle = "menu-main-1__item--no-button";
    var itemActiveStyle = "menu-main-1__item--active";
    var itemNoActionStyle = "menu-main-1__item--no-action-in-desktop";
    var closingButtonStyle = "menu-main-1__closing-button";
    var actionStyle = "menu-main-1__action";
    var menuMain1Clone = "menu-main-1-clone";

    // Clone menu
    var cloneMenu = function () {
        // console.log('clone');
        var menuMainClone = document.getElementById(menuMain1Clone);

        if (menuMainClone) {
            menuMainClone.remove();
        }
        var menuMainId = document.getElementById("menu-main");
        var menuMainCloned = menuMainId.cloneNode(true);

        menuMainCloned.id = menuMain1Clone;

        var parentNode = menuMainId.parentNode;

        parentNode.insertBefore(menuMainCloned, menuMainId);
        menuMainId.style.display = 'none';

        menuMainClone = document.getElementById(menuMain1Clone);

        menuMainClone.style.display = "block";
    }

    // Generate unique id
    var uniqId = function () {
        return Math.round(new Date().getTime() + (Math.random() * 100));
    };

    var initLevel = function () {
        //console.log('init level');
        var relationId = [];
        var items = [].slice.call(document.querySelectorAll('#' + menuMain1Clone + ' .' + itemStyle));

        items.forEach(function (item, index) {
            // Check if item have not the class 'menu-main-1__item--no-action-in-desktop'
            if (item.classList.contains(itemNoActionStyle) === false || media !== 'mediaDesktop') {
                // Wrap link
                var link = item.children[0];
                var linkHtml = link.outerHTML;
                var linkWrapper = '<div class="menu-main-1__header">' + linkHtml + '</div>';

                item.children[0].outerHTML = linkWrapper;

                var header = item.children[0];
                var linkHeader = header.children[0];

                // Add button
                var linkNoButton = item.classList.contains(itemNoButtonStyle);

                subLevel = item.children[1];
                var closingButton;
                var closingButtonExist;

                // If there is a children
                if (subLevel) {
                    // Item without button just a link
                    if (linkNoButton === true) {
                        relationId[index] = 'relationship-' + uniqId();

                        linkHeader.classList.add(actionStyle);

                        setAttributes(linkHeader, {
                            'role':          'button',
                            'aria-controls': relationId[index],
                            'aria-haspopup': 'true',
                            'aria-expanded': 'false'
                        });

                        subLevel = item.children[1];

                        setAttributes(subLevel, {
                            'id':          relationId[index],
                            'aria-hidden': 'true'
                        });

                        // Add closing button
                        closingButtonExist = item.children[1].children[1];
                        if (!closingButtonExist) {
                            closingButton = document.createElement('button');
                            closingButton.innerHTML = 'Fermer';
                        } else {
                            closingButton = closingButtonExist
                        }
                        setAttributes(closingButton, {
                            'type':          'button',
                            'aria-controls': relationId[index],
                            'aria-haspopup': 'true',
                            'aria-expanded': 'false',
                            'class':         closingButtonStyle
                        });
                        subLevel.appendChild(closingButton);
                    } else {
                        var button = document.createElement('button');
                        button.classList.add(actionStyle);
                        button.innerHTML = 'Déplier le menu';
                        relationId[index] = 'relationship-' + uniqId();

                        addClass(button, 'menu-main-1__button');
                        setAttributes(button, {
                            'type':          'button',
                            'aria-controls': relationId[index],
                            'aria-haspopup': 'true',
                            'aria-expanded': 'false'
                        });

                        header.appendChild(button);

                        setAttributes(subLevel, {
                            'id':          relationId[index],
                            'aria-hidden': 'true'
                        });

                        // Add closing button
                        closingButtonExist = item.children[1].children[1];
                        if (!closingButtonExist) {
                            closingButton = document.createElement('button');
                            closingButton.innerHTML = 'Fermer';
                        } else {
                            closingButton = closingButtonExist
                        }

                        closingButton.innerHTML = 'Fermer';
                        setAttributes(closingButton, {
                            'type':          'button',
                            'aria-controls': relationId[index],
                            'aria-haspopup': 'true',
                            'aria-expanded': 'false',
                            'class':         closingButtonStyle
                        });
                        subLevel.appendChild(closingButton);
                    }
                }
            }
        });
    };

    var calcHeightListitems = function (item) {
        var subLevel = item.children[1];
        var parentNode = item.parentNode;
        var heightParentNode = outerHeight(parentNode);

        subLevel.style.visibility = 'visible';
        subLevel.style.display = 'block';
        var heightSubLevel = outerHeight(subLevel);
        subLevel.style.visibility = null;
        subLevel.style.display = null;

        if (parentNode.classList.contains('menu-main-1__listitems-3')) {
            //console.log('level 3');
            var listitems2 = findAncestor(item, 'menu-main-1__listitems-2');
            var heightListitems2 = outerHeight(listitems2);

            //console.log('heightParentNode: '+heightParentNode);
            //console.log('heightListitems2: '+heightListitems2);
            //console.log('heightSubLevel: '+heightSubLevel);
            if (heightSubLevel > heightListitems2 || heightSubLevel > heightParentNode) {
                parentNode.style.height = heightSubLevel + "px";
                listitems2.style.height = heightSubLevel + "px";
            }
        } else if (parentNode.classList.contains('menu-main-1__listitems-2')) {
            //console.log('level 2');
            if (heightParentNode < heightSubLevel) {
                parentNode.style.height = heightSubLevel + "px";
            }
        }
    }

    var openSubLevel = function (opener, item) {
        var subLevel = item.children[1];

        // Check if view is desktop and attribute data-menu-main-one-calc is true
        if (media === 'mediaDesktop' && dataCalc === 'true') {
            calcHeightListitems(item);
        }

        var currentClosingButton = item.children[1].children[1];

        // Change opener text
        if (opener.tagName === 'BUTTON') {
            opener.firstChild.data = "Replier le menu";
        }
        // change opener state
        setAttributes(opener, {'aria-expanded': 'true'});
        // change closing button state
        setAttributes(currentClosingButton, {'aria-expanded': 'true'});
        // Add active class to item
        addClass(item, itemActiveStyle);
        // Change sublevel state
        setAttributes(subLevel, {'aria-hidden': 'false'})
    };

    var closeSubLevel = function (item, tag) {
        //console.log('closeSubLevel');
        function filterLi(item) {
            return item.nodeName.toLowerCase() == 'li';
        }

        var siblings = getSiblings(item, filterLi);

        // Close this and all bothers
        siblings.forEach(function (item) {
            removeClass(item, itemActiveStyle);
            var currentButton = item.children[0].querySelector('.' + actionStyle);
            var subLevel = item.children[1];

            if (currentButton) {
                if (currentButton.tagName === 'BUTTON') {
                    currentButton.firstChild.data = "Déplier le menu";
                }
                setAttributes(currentButton, {
                    'aria-expanded': 'false'
                });
            }

            if (subLevel) {
                var currentClosingButton = item.children[1].children[1];
                setAttributes(subLevel, {
                    'aria-hidden': 'true'
                });
                if (currentClosingButton) {
                    setAttributes(currentClosingButton, {
                        'aria-expanded': 'false'
                    });
                }
            }
        });
    };

    var manageOpenClose = function (opener, item, tag) {
        // If click elsewhere
        if (opener === false) {
            //console.log('closing because click elsewhere');
            closeSubLevel(item, tag);
        }
        // Closing
        else if (opener.getAttribute('aria-expanded') === 'true') {
            //console.log('closing');
            closeSubLevel(item, tag);
        }
        // Opening
        else {
            //console.log('opening');
            closeSubLevel(item, tag);
            openSubLevel(opener, item);
        }
    };

    // run cloning
    cloneMenu();
    // init menu
    initLevel();

    var actionButton = [].slice.call(document.querySelectorAll('#' + menuMain1Clone + ' .' + actionStyle));
    var closingButtonAction = [].slice.call(document.querySelectorAll('#' + menuMain1Clone + ' .' + closingButtonStyle));

    eventFunction = function (e) {
        //console.log('eventFunction');
        var event = e.srcElement || e.target;
        var item = event.parentNode.parentNode;

        manageOpenClose(event, item, event.tagName);
        e.preventDefault();
        item.removeEventListener('click', eventFunction, false);
    };

    closingButtonFunction = function (e) {
        var event = e.srcElement || e.target;
        var item = event.parentNode.parentNode;

        closeSubLevel(item, event.tagName);
        item.removeEventListener('click', closingButtonFunction, false);
        e.preventDefault();
    }
    actionButton.forEach(function (action) {
        //console.log('click on button');
        action.addEventListener('click', eventFunction, false);
        /*
        action.addEventListener('keydown', function(e){
            var code = e.charCode || e.keyCode;
            if (code === 32 || code === 13) {
                eventFunction(e);
            }
        }, false);
        */
    });

    // Event on closing button
    closingButtonAction.forEach(function (item) {
        //console.log('click on closing button');
        item.addEventListener('click', closingButtonFunction, false);
    });

    // Click elsewhere
    document.addEventListener('click', function (e) {
        var event = e.srcElement || e.target;
        if (!findAncestor(event, 'menu-main-1')) {
            //console.log('click elsewhere');
            var items = [].slice.call(document.querySelectorAll('#' + menuMain1Clone + ' .' + itemStyle));
            var item = items[1]
            var tag = item.children[0].children[0].tagName;
            //console.log(item);
            manageOpenClose(false, item, tag);
        }
    }, false);

    // Toggle class on body
    var menuMainToggle = function() {
        if (document.body.classList.contains('menu-main-1--active')) {
            document.body.classList.remove('menu-main-1--active');
        } else {
            document.body.classList.add('menu-main-1--active');
        }
    }

    if (media !== 'mediaDesktop') {
        document.querySelector('.menu-main-1__listitems-0 > .menu-main-1__item > .menu-main-1__header > .menu-main-1__action').addEventListener('click', function () {
            menuMainToggle();
        }, false);
        document.querySelector('.menu-main-1__listitems-0 > .menu-main-1__item > .menu-main-1__sublevel > .menu-main-1__closing-button').addEventListener('click', function () {
            menuMainToggle();
        }, false);
    }
}

function menuMain1State(state) {
    switch (state) {
        case 'mediaSmartphone':
            menuMain1(state);
            break;
        case 'mediaTablet':
            menuMain1(state);
            break;
        case 'mediaDesktop':
            menuMain1(state);
            break;
    }
}
