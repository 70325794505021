function languagePopup(view) {
	if (view === 'mediaDesktop') {
		$('.language').dropDownMenu({
	        disabled:    true,
	        classBtn:    'language-title',
	        independent: false
	    });
	} else if ((view === 'mediaSmartphone') || (view === 'mediaTablet'))  {
		$('.language').dropDownMenu({
	        disabled:    false,
	        classBtn:    'language-title',
	        independent: false
	    });
	}
}
