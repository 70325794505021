function readpseaker() {
    var container = document.getElementById('content');
    var encodeUrl = encodeURIComponent(window.location.href);
    var customerId = '5604';
    var readId = '';
    var readClass = 'heading,section-main__content';

    if (container) {

        function preserveScript(elements) {
            var preserveElements = container.querySelectorAll(elements);

            for (var i = 0; i < preserveElements.length; i++) {
                preserveElements[i].classList.add('rs_preserve');
            }
        }

        function ignoreContent(elements) {
            var ingoreElements = document.querySelectorAll(elements);

            for (var i = 0; i < ingoreElements.length; i++) {
                ingoreElements[i].classList.add('rs_skip');
            }
        }

        preserveScript('.classical-gallery, .ddm, .block-location');
        ignoreContent('.classical-gallery, form, .block-location');

        var markup = '' +
            '<div class="readspeaker__container">' +
                '<div class="readspeaker__wrapper">' +
                    '<div id="readspeaker_button1" class="rs_skip rsbtn rs_preserve">' +
                        '<a rel="nofollow" class="rsbtn_play" accesskey="L" title="Ecoutez le texte avec ReadSpeaker webReader" href="//app-eu.readspeaker.com/cgi-bin/rsent?customerid=' + customerId + '&amp;lang=fr_fr&amp;readid=' + readId + '&amp;readclass=' + readClass + '&amp;url=' + encodeUrl + '">' +
                            '<span class="rsbtn_left rsimg rspart"><span class="rsbtn_text"><span>Écouter</span></span></span>' +
                            '<span class="rsbtn_right rsimg rsplay rspart"></span>' +
                        '</a>' +
                    '</div>' +
                '</div>' +
            '</div>';

        container.insertAdjacentHTML('afterbegin', markup);
    }
}



