$(document).ready(function() {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';


    var carouselAlt = carrousel3({
        container: '.web-gallery',
        mediaDesktop: {
            carouselVisible: 4,
            allowWrap: true
        },
        mediaTablet: {
            carouselVisible: 3,
            allowWrap: true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap: true
        }
    });


    var carouselImages = carrousel3({
        container: '.image-gallery',
        mediaDesktop: 'destroy',
        mediaSmartphone: {
            carouselVisible: 1,
            onInit: function($container, $carousel, $items) {
                $carousel.empty().append($items);
                initResizeCarouselArrows($container, $carousel, $items);
            },
            onDestroy: function($container, $carousel, $items) {
                var struct = [
                    { class: 'image-gallery-sm', num: 2 },
                    { class: 'image-gallery-sm', num: 2 },
                    { class: 'image-gallery-lg', num: 1 }
                ];

                var
                    i = 0,
                    jj = 0,
                    j,
                    len = $items.length,
                    key;

                var
                    $elems = $(),
                    $elem;

                for (; i < struct.length; i++) {
                    $elem = $('<div />').addClass(struct[i].class);

                    for (j = 0; j < struct[i].num && jj < len; j++, jj++) {
                        $elem.append($items.eq(jj));
                    }

                    $elems = $elems.add($elem);
                }

                $elems.find('a').attr('tabindex', '0');

                $carousel.append($elems);

                destroyResizeCarouselArrows($container, $carousel, $items);
            }
        }
    });

    function initResizeCarouselArrows($container, $carousel, $items) {
        setTimeout(function() {
            var
                $arrows = $container.find('.prevCaroussel3, .nextCaroussel3'),
                $sentinelImg = $container.find('.cycle-sentinel img');

            setHeight();
            $(window).on('resize.resizeCarouselArrows', setHeight);

            function setHeight() {
                $arrows.height($sentinelImg.height());
            }
        }, 500);
    }

    function destroyResizeCarouselArrows($container, $carousel, $items) {
        $(window).off('.resizeCarouselArrows');
        $container.find('.prevCaroussel3, .nextCaroussel3').height('');
    }

    var jRes = jRespond([{
        label: 'smartphone',
        enter: 0,
        exit: 767
    }, {
        label: 'tablet',
        enter: 768,
        exit: 1279
    }, {
        label: 'desktop',
        enter: 1280,
        exit: 10000
    }]);

    jRes.addFunc([{
            breakpoint: 'smartphone',
            enter: function() {
                myInitFuncSmartphone();

            },
            exit: function() {}
        },
        {
            breakpoint: 'tablet',
            enter: function() {
                myInitFuncTablet();

            },
            exit: function() {}
        },
        {
            breakpoint: 'desktop',
            enter: function() {
                myInitFuncDesktop();

            },
            exit: function() {}
        }
    ]);

    function myInitFuncSmartphone() {
        menuStratis(mediaSmartphone); // 190_stratis_menu.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carrousel4State(mediaSmartphone); // 331_stratis_carrousel1.js
        //carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        //menuMain1State(mediaSmartphone);
        carrousel4State(mediaSmartphone); // 331_stratis_carrousel1.js
        carouselImages(mediaSmartphone); // 341_stratis_carrousel3.js
        carouselAlt(mediaSmartphone);

        languagePopup(mediaSmartphone);
    }

    function myInitFuncTablet() {
        menuStratis(mediaTablet); // 190_stratis_menu.js
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        carrousel4State(mediaTablet); // 331_stratis_carrousel1.js
        //carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        //menuMain1State(mediaTablet);
        carrousel4State(mediaTablet); // 331_stratis_carrousel1.js
        carouselImages(mediaTablet); // 341_stratis_carrousel3.js
        carouselAlt(mediaTablet);

        languagePopup(mediaTablet);
    }

    function myInitFuncDesktop() {
        menuStratis(mediaDesktop); // 190_stratis_menu.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carrousel4State(mediaDesktop); // 331_stratis_carrousel1.js
        //carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        //menuMain1State(mediaDesktop);
        languagePopup(mediaDesktop);
        carouselImages(mediaDesktop); // 341_stratis_carrousel3.js
        carouselAlt(mediaDesktop);

    }

    // Generic functions
    preloadImages(); // 170_stratis_preload_images.js
    objectFitImages(); // 175_lib_ofi.min.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    sharePage();
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome(); // 235_stratis_fancybox_auto_home.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    rightMenuDdm(); // 271_stratis_right-menu_ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    enableTimePicker(); // 310_stratis_datetimepicker.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    menuMain(); // 190_stratis_menu_main.js
    readpseaker();

    var headerChildren = $('#header').children().length;

    if (headerChildren > 1) {
        $('.top-of-content').addClass('top-of-content--with-banner');
        $('.header-bar-inner').removeClass('header-bar-inner--no-banner');
    } else {
        $('.header-bar-inner').addClass('header-bar-inner--no-banner');
    }

});

jQuery(function($) {
    var max_col_height = 0;
    $('.hero-gallery-content').each(function() {
        if ($(this).height() > max_col_height) {
            max_col_height = $(this).height();
        }
    });
    $('.hero-gallery-content').height(max_col_height);
});

accessTooltipState(); // 400_stratis_access-tooltip.js
locationChange();

function locationChange() {
    $('.block-location').each(function() {
        var
            $win = $(window),
            $elem = $(this),
            $mainWrapper = $('.heading + .section-main__wrapper .section-main__wrapper-2'),
            $content = $('.section-main__content'),
            media = getMedia();

        init();

        function init() {
            changeLayout();

            $win.on('resize orientationchange', function() {
                var m = getMedia();

                if (m !== media) {
                    media = m;
                    changeLayout();
                }
            });
        }

        function changeLayout() {
            if (media === 'mediaDesktop') {
                if ($mainWrapper.length) {
                    $elem.insertAfter($mainWrapper);
                }
            } else if (media === 'mediaTablet' || media === 'mediaSmartphone') {
                if ($content.length) {
                    $elem.insertAfter($content);
                }
            }
        }
    });
}

function getMedia() {
    var
        m = 'mediaDesktop',
        w = window.innerWidth;

    if (w > 767 && w < 1221) {
        m = 'mediaTablet';
    } else if (w < 768) {
        m = 'mediaSmartphone';
    }

    return m;
}

$(window).on('load', function() {
    var
        $list = $('.social-list'),
        $filterBtns = $('.filter-social [data-filter]');

    $list.isotope({
        itemSelector: 'li',
        masonry: {}
    });

    filter('.social-list-all');
    toggleActive($filterBtns.eq(0));

    $('.filter-social').on('click', 'a', function(e) {
        e.preventDefault();

        var $elem = $(this);

        filter($elem.attr('data-filter'));
        toggleActive($elem);
    });

    function filter(val) {
        $list.isotope({
            filter: val
        });
    }

    function toggleActive($elem) {
        deactivateAll();
        makeActive($elem);
    }

    function makeActive($elem) {
        $elem.addClass('active');
    }

    function deactivateAll() {
        $filterBtns.removeClass('active');
    }
});

function heroArrows() {
    setTimeout(function() {
        var
            $container = '.hero-gallery',
            $arrows = $($container).find('.prevcarrousel1, .nextcarrousel1'),
            $sentinelImg = $($container).find('.cycle-sentinel img');

        setHeight();

        $(window).on('resize.resizeCarouselArrows', setHeight);

        function setHeight() {
            $arrows.height($sentinelImg.height());
        }
    }, 500);
}
heroArrows();



function addClassSub2() {
    $('.menu-main__subul-level-2 > li').each(function() {
        var item = $(this);
        if (item.find('.menu-main__level-3').length === 0) {
            item.addClass('noSublevel');
        }
    })
};
addClassSub2();

