
/*
<section class="caroussel1">
  <div class="listItems">
    <div class="item" style="background-image: url('../Images/temp/caroussel1.jpg');">
      <div class="wrapper">
        <h2 class="title"><a href="#" class="linkBlock">1Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</a></h2>
        <p class="moreInfos"><a href="#">Découvrir l'école</a></p>
      </div>
    </div>
    <div class="item" style="background-image: url('../Images/temp/caroussel2.jpg');">
      <div class="wrapper">
        <h2 class="title">2Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</h2>
        <p class="moreInfos"><a href="#">Découvrir l'école</a></p>
      </div>
    </div>
  </div>
</section>
*/

// Caroussel1
function carrousel4Desktop() {
  var carrouselWrap = $('.carrousel4');
  if(carrouselWrap.length > 0) {
    var carrousel = $('.listItems', carrouselWrap),
      items = carrousel.find('.item'),
      firstItemImg = items.eq(0).find('img');

    if($('.buttonStopHero').length == 0) {
      //console.log('btn active');
      carrousel.parent().append('<button type="button" class="button-reset buttonStopHero modeplay"><img src="' + pathImg + 'carrousel4/pause.png" alt=""> <span class="txt">' + l10n['stopCarrousel'] + '</span></button>');
    }

    if($('.prevNextcarrousel4').length == 0) {
      carrousel.parent().append('<ul class="prevNextcarrousel4">' +
        '<li class="prevcarrousel1"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel1/prev.png" alt="' + l10n['prev'] + '"></button></li>' +
        '<li class="nextcarrousel1"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel1/next.png" alt="' + l10n['next'] + '"></button></li>' +
        '</ul>');
    }
    if($('.pagercarrousel4').length == 0) {
      carrousel.parent().append('<ul class="pagercarrousel4 hero-gallery-pagination"></ul>');
    }

    var buttonStopHero = $('.buttonStopHero', carrouselWrap);

    // Gestion des items actifs sur le pager A11Y
    $(document, carrousel).on('cycle-update-view', function (e, opts, slideOpts) {
      var pagers;
      if(opts.pager) {
        pagers = opts.API.getComponent('pager');
        pagers.each(function () {
          var titlePager = $(this).children().eq(opts.currSlide).find('button img').attr('alt');

          // Class active
          $(this).children().find('li').removeClass(opts.pagerActiveClass).eq(opts.currSlide).addClass(opts.pagerActiveClass);

          // Active title button
          $(this).children().find('button').removeAttr('title');
          $(this).children().find('button img').attr('src', pathImg + 'carrousel1/pager-off.png');
          $(this).children().eq(opts.currSlide).find('button').attr('title', titlePager + ' - ' + l10n['activeSlide']);
          $(this).children().eq(opts.currSlide).find('button img').attr('src', pathImg + 'carrousel1/pager-on.png');
        });
      }
    });

    // Gestion du pager
    $(document, carrousel).on('cycle-bootstrap', function (e, opts, API) {
      API.buildPagerLink = function (opts, slideOpts, slide) {
        var pagerLink;
        var pagers = opts.API.getComponent('pager');
        pagers.each(function () {
          var pager = $(this);
          var markup = $('\
            <li>\
              <div class="hero-gallery-pagination-text">\
                <a href="{{link}}">\
                <strong>{{title}}</strong>\
                <p>{{text}}</p>\
                </a>\
              </div>\
            </li>'
            .replace('{{title}}', $('.hero-gallery-category', slide).text())
            .replace('{{text}}', $('.hero-gallery-title', slide).text())
            .replace('{{link}}', $('.hero-gallery-link', slide).attr('href'))
          );
          pagerLink = $(markup).appendTo(pager);
          /*pagerLink.on(opts.pagerEvent, function (e) {
            e.preventDefault();
            opts.API.page(pager, e.currentTarget);
          });*/
        });
      };

      // updateArrowsHeight();
    });

    carrousel.cycle({
      log: false,
      fx: 'fade',
      speed: '600',
      timeout: 4000,
      prev: '.prevcarrousel1 button',
      next: '.nextcarrousel1 button',
      slides: '.item',
      //autoHeight: '460:345',
      pager: '.pagercarrousel4'
    });

    // $(window).on('resize', updateArrowsHeight);

    //carousel.cycle('pause');

    // Arret de l'animation
    var animActive = true;

    function pauseAnim() {
      carrousel.cycle("pause");
      buttonStopHero.find('img').attr('src', pathImg + 'carrousel4/play.png').parent().find('.txt').html(l10n['startCarrousel']);
      animActive = false;
    }

    function resumeAnim() {
      carrousel.cycle("resume");
      buttonStopHero.find('img').attr('src', pathImg + 'carrousel4/pause.png').parent().find('.txt').html(l10n['stopCarrousel']);
      animActive = true;
    }

    function resumePauseAnimation(etat) {
      if(etat === "pause") {
        pauseAnim();
      }
      else if(etat === "resume") {
        resumeAnim();
      }
      else {
        if(animActive === true) {
          pauseAnim();
        }
        else {
          resumeAnim();
        }
      }
    }

    // function updateArrowsHeight() {
    //   $('.prevcarrousel4, .nextcarrousel4', carrouselWrap)
    //     .height(firstItemImg.height());
    // }

    $('.nextcarrousel1 button').click(function () {
      pauseAnim();
    });
    $('.prevcarrousel1 button').click(function () {
      pauseAnim();
    });
    $('.pagercarrousel1 button').click(function () {
      pauseAnim();
    });
    buttonStopHero.click(function () {
      resumePauseAnimation();
    });
  }
}

function carrousel4State(view) {
  if(
    view === 'mediaDesktop' ||
    view === 'mediaTablet' ||
    view === 'mediaSmartphone'
  ) {
    carrousel4Desktop();
  }
}
