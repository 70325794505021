// Menu principal
function menuMain() {
  var state = getState();

  init(state);

  function init(state) {
    changeState(state);
    $(window).on('resize', resizeHandler);
    $(document).on('click', close);
  }

  function changeState(state) {
    if(state == 'mediaDesktop') {
      initDesktop();
    }
    else if(state == 'mediaSmartphone') {
      initSmartphone();
    }
  }

  function initSmartphone() {
    var
        $mainNav = $('.menu-main').clone(),
        $search = $('.search-box').clone(),
        $quickAccess = $('.right-menu_button .ddm__button').clone(),
        $closeBtn = $('.close-btn').clone(),
        $container = $('.mobile-menu');

        $mainNav
          .attr('class', 'menu-main')
          .appendTo($container);

        $search
          .attr('class', 'search-box')
          .appendTo($container);

        $quickAccess
          .attr('class', 'quick-access-btn')
          .appendTo($container);

        $closeBtn
          .attr('class', 'close-btn')
          .appendTo($container);


    $('.menu-main ul li > .menu-main__sublink-icon').bind('click', function (e) {
      e.preventDefault();
      // e.stopPropagation();
      if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
      }
      else {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().siblings().children('[class^=menu-main__level-]').removeClass('show');
        $(this).parent().addClass('active');
        $(this).nextAll('[class^=menu-main__level]').addClass('show');
      }
    });
    $('.menu-main ul li > [class^="menu-main__sublink-level-1"]').bind('click', function (e) {
      e.preventDefault();
      // e.stopPropagation();
      if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
      }
      else {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().siblings().children('[class^=menu-main__level-]').removeClass('show');
        $(this).parent().addClass('active');
        $(this).nextAll('[class^=menu-main__level]').addClass('show');
      }
    });

    $('.menu-main ul li > [class^="menu-main__sublink-level-"]')
      .not('.menu-main__sublink-level-1')
      .bind('click', function (e) {
        // e.preventDefault();
        // e.stopPropagation();
        if($(this).parent().parent().hasClass('active')) {
          $(this).parent().parent().removeClass('active');
          $(this).parent().nextAll('[class^=menu-main__level-]').removeClass('show');
        }
        else {
          $(this).parent().parent().siblings().removeClass('active');
          $(this).parent().parent().siblings().children('[class^="menu-main__sublink-level-"]').nextAll('[class^=menu-main__level-]').removeClass('show');
          $(this).parent().parent().addClass('active');
          $(this).parent().nextAll('[class^=menu-main__level-]').addClass('show');
        }
      });

    $('.show-btn').on('click', function () {
      $(this).closest('.mobile-component').addClass('show');
      $('#page').addClass('menu-show');
      $('#menu-main').addClass('show');
    });

    $('.close-btn').on('click', function () {
      $(this).closest('.mobile-component').removeClass('show');
      $('#page').removeClass('menu-show');
      $('#menu-main').removeClass('show');
    });
  }

  function initDesktop() {
    $('.menu-main ul li > [class^="menu-main__sublink-level-1"]').bind('click', function (e) {
      e.preventDefault();
      // e.stopPropagation();
      if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
      }
      else {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().siblings().children('[class^=menu-main__level-]').removeClass('show active');
        $(this).parent().addClass('active');
        $(this).nextAll('[class^=menu-main__level-]').addClass('show');
      }
    });
    $('.menu-main ul li > .menu-main__sublink-icon').bind('click', function (e) {
      e.preventDefault();
      // e.stopPropagation();
      if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).nextAll('[class^=menu-main__level-]').removeClass('show');
        $(this).parents('.menu-main__level-2').removeClass('active');
      }
      else {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().siblings().children('[class^="menu-main__sublink-level-"]').next().removeClass('show');
        $(this).parent().addClass('active');
        $(this).nextAll('[class^=menu-main__level-]').addClass('show');
        $(this).parents('.menu-main__level-2').addClass('active');
      }
    });

    $('.menu-main__closer').bind('click', function (e) {
      e.preventDefault();
      //'[class ^= pre]'
      $('.menu-main ul li.active').children('[class^="menu-main__sublink-level-"]').next().removeClass('show');
      $('.menu-main ul li.active').removeClass('active');
    });

    $('.menu-main__level-3-back').on('click', function (e) {
      e.preventDefault();

      var $parent = $(this).parents('.menu-main__level-3');

      $parent
        .removeClass('show')
        .parent()
        .removeClass('active');

      $(this).parents('.menu-main__level-2').removeClass('active');
    });
  }

  function resizeHandler() {
    var s = getState();

    if(state !== s) {
      state = s;
      changeState(state);
    }
  }

  function close(e) {
    if(state !== 'mediaSmartphone' && !$(e.target).closest('.menu-main__level-1').length) {
      $('.menu-main__level-1').find('.active, .show').removeClass('active show');
    }
  }

  function getState() {
    var
      m = 'mediaDesktop',
      w = window.innerWidth;

    if(w < 1280) {
      m = 'mediaSmartphone';
    }

    return m;
  }
}
